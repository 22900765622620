/**
 * This file handles provides a api connector function used by the Graphql Generator
 */
import { message } from "antd";
import { api } from "./api";
import { handleGraphqlErrors } from "./utils";

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    try {
      const { data } = await api.post<{ data: TData; errors: any }>(
        "/graphql",
        {
          query,
          variables,
        }
      );

      if (data?.errors) {
        handleGraphqlErrors(data?.errors);
      }

      return data?.data;
    } catch (e) {
      if (e.response.data?.errors) {
        console.log("here");

        message.error(e.response.data?.errors[0].message);
      }

      throw e;
    }
  };
};
